import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

window.onload = () => {
    if($(".webgl").length > 0){
        const scene = new THREE.Scene();
        // scene.background = new THREE.Color(0xdddddd);

        const clock = new THREE.Clock();

        const camera = new THREE.PerspectiveCamera(60,window.innerWidth/window.innerHeight,10,1000);
        camera.position.set(230, 258, 140);
        camera.lookAt(0,0,0);

        const renderer = new THREE.WebGLRenderer({antialias:true});
        renderer.setSize(window.innerWidth,window.innerHeight);
        renderer.gammaOutput = true;
        renderer.outputEncoding = 'linear';

        const light1 = new THREE.AmbientLight(0xDDDDDD);
        scene.add(light1);

        const light2 = new THREE.DirectionalLight(0xFFFFFF,1);
        scene.add(light2);
        
        const container = document.getElementById('webgl');
        container.appendChild(renderer.domElement);

        let loader = new GLTFLoader();
        loader.load('./images/math.glb', function(gltf){
            // console.log(gltf);
            const model = gltf.scene;
            // model.scale.set(1,1,1);
            gltf.scene.traverse( child => {
                if ( child.material ) child.material.metalness = 0;
            } );
            scene.add(model);
            animate();
        }, function(xhr){
            // console.log((xhr.loaded/xhr.total * 100) + "% loaded");
        }, function ( error ) {
            // console.error( error );
        } );

        const controls = new OrbitControls( camera, renderer.domElement );
        controls.target.set(0, 0, 0);
        controls.update();
        controls.enablePan = false;
        controls.enableDamping = true;
        controls.autoRotate = true;
        controls.autoRotateSpeed = -1;

        function animate() {
            controls.update( clock.getDelta() );
            renderer.render(scene,camera);

            requestAnimationFrame(animate);
        }

        window.addEventListener( 'resize', onWindowResize );

        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize( window.innerWidth, window.innerHeight );

        }
    } // end if webgl exist
} // end load