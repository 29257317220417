import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

document.addEventListener('DOMContentLoaded', function() {
    if($(".gallery-slide").length > 0){
        $(".gallery-slide").slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            swip: false,
            variableWidth: true,
            autoplay: true,
            autoplaySpeed: 5000,
            centerMode: false,
            responsive: [
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  swip: true
                }
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
        });

        $(".gallery-next").on("click", function(e){
          $(".gallery-slide").slick('slickNext');

          e.preventDefault();
        });
    } // end if length
    if($(".home-banner.slick").length > 0){
      $(".home-banner.slick").slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          swip: false,
          autoplay: true,
          autoplaySpeed: 5000,
          prevArrow: '<button type="button" class="slick-prev"></button>',
          nextArrow: '<button type="button" class="slick-next"></button>'
      });
    
    }
    if($(".index-award.slick").length > 0){
      $(".index-award.slick").slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false,
          swip: false,
          autoplay: true,
          autoplaySpeed: 5000,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
      });
    
    }  
});